
    import { IonList, IonFab, IonFabButton, IonIcon, IonFabList, IonLoading,IonRefresher,  IonRefresherContent} from '@ionic/vue';
    import ActivityCard from "@/components/card/ActivityCard.vue";
    import EventCard from "@/components/card/EventCard.vue";
    import { add, eyeOutline, eyeOffOutline,accessibilityOutline, medalOutline, searchOutline, refresh} from 'ionicons/icons';
    import BaseLayout from "@/components/BaseLayout.vue";
    import HomeItem from "@/components/item/HomeItem.vue";
    import MissingCard from "@/components/card/MissingCard.vue";
    import { mapGetters} from "vuex";

    export default  {
        name: 'Home',
        components: {
          BaseLayout,
          EventCard,
          ActivityCard,
          IonList,
          IonFab,
          IonFabButton,
          IonIcon,
          HomeItem,
          MissingCard,
          IonFabList,
          IonLoading,
          IonRefresher,
          IonRefresherContent
        },
        setup() {
            return {
              add,
              eyeOutline,
              eyeOffOutline,
              accessibilityOutline,
              medalOutline,
              searchOutline,
              refresh
            }
        },
      methods:{
        showActivityAction(){
          this.showActivity = !this.showActivity;
        },
        showEventsAction(){
          this.showEvents = !this.showEvents;
        },
        refreshData($event){
          this.$store.dispatch('account');
          $event.target.complete();
        }
      },
      computed:{
          ...mapGetters([
            'upcomingEvents',
            'upcomingActivities',
            'hasActivities',
            'hasEvents',
            'loading'
          ])
      },
        data(){
            return{
              showActivity: true,
              showEvents: true,
                }
            }
        }

