import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_item_group = _resolveComponent("ion-item-group")!

  return (_openBlock(), _createBlock(_component_ion_item_group, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item_divider, {
        button: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.showItemAction()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.name), 1)
            ]),
            _: 1
          }),
          ($data.showItem)
            ? (_openBlock(), _createBlock(_component_ion_icon, {
                key: 0,
                icon: $setup.eyeOffOutline,
                slot: "end"
              }, null, 8, ["icon"]))
            : (_openBlock(), _createBlock(_component_ion_icon, {
                key: 1,
                icon: $setup.eyeOutline,
                slot: "end"
              }, null, 8, ["icon"]))
        ]),
        _: 1
      }),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}