
import { IonCard, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonButton  } from '@ionic/vue';
import { people} from 'ionicons/icons';

export default  {
  name: 'MissingCard',
  props:{
    missing: String,
    linkEnabled: {
      type: Boolean,
      default: true
    }

  },
  components: {
    IonCard,
    IonCardTitle,
    IonCardSubtitle,
    IonCardHeader,
    IonCardContent,
    IonButton
  },
  computed:{
    find(){
      let miss ={};
      if(this.missing === 'activity')
        miss= {name: 'Activities', link: '/activity/find'};
      else if(this.missing === 'event')
        miss= {name: 'Events', link: '/events'};
      else if(this.missing === 'group')
        miss= {name: 'Groups', link: '/groups/find'}

      return miss;
    }
  },
  setup() {
    return {
      people
    }
  }
}

