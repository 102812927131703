
import {  IonIcon, IonItemGroup, IonLabel, IonItemDivider} from '@ionic/vue';
import {  eyeOutline, eyeOffOutline} from 'ionicons/icons';

export default  {
  name: 'HomeItem',
  components: {
    IonIcon,
    IonItemGroup,
    IonLabel,
    IonItemDivider
  },
  setup() {
    return {
      eyeOutline,
      eyeOffOutline
    }
  },
 props:{
    name: String,
   type: String,

  },
  methods:{
    showItemAction(){
      this.showItem = !this.showItem;
      this.$emit('showItem');

    },
  },
  computed:{
  },
  data(){
    return{
      showItem: true,
    }
  }
}


