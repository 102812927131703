import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_activity_card = _resolveComponent("activity-card")!
  const _component_missing_card = _resolveComponent("missing-card")!
  const _component_home_item = _resolveComponent("home-item")!
  const _component_event_card = _resolveComponent("event-card")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab_list = _resolveComponent("ion-fab-list")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_BaseLayout = _resolveComponent("BaseLayout")!

  return (_openBlock(), _createBlock(_component_BaseLayout, { title: "Home" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_refresher, {
        slot: "fixed",
        "pull-factor": "0.5",
        "pull-min": "100",
        "pull-max": "150",
        onIonRefresh: $options.refreshData
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher_content, {
            "pulling-icon": $setup.refresh,
            refreshingSpinner: "crescent"
          }, null, 8, ["pulling-icon"])
        ]),
        _: 1
      }, 8, ["onIonRefresh"]),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_ion_loading, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ion_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_home_item, {
                  name: "My Activities",
                  onShowItem: _cache[0] || (_cache[0] = ($event: any) => ($options.showActivityAction()))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.upcomingActivities, (activity) => {
                      return _withDirectives((_openBlock(), _createBlock(_component_activity_card, {
                        key: activity.id,
                        activity: activity
                      }, null, 8, ["activity"])), [
                        [_vShow, $data.showActivity]
                      ])
                    }), 128)),
                    _withDirectives(_createVNode(_component_missing_card, { missing: 'activity' }, null, 512), [
                      [_vShow, !_ctx.hasActivities]
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_home_item, {
                  name: "My Events",
                  onShowItem: _cache[1] || (_cache[1] = ($event: any) => ($options.showEventsAction()))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.upcomingEvents, (event) => {
                      return _withDirectives((_openBlock(), _createBlock(_component_event_card, {
                        key: event.id,
                        event: event
                      }, null, 8, ["event"])), [
                        [_vShow, $data.showEvents]
                      ])
                    }), 128)),
                    _withDirectives(_createVNode(_component_missing_card, { missing: 'event' }, null, 512), [
                      [_vShow, !_ctx.hasEvents]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])),
      (!_ctx.loading)
        ? (_openBlock(), _createBlock(_component_ion_fab, {
            key: 2,
            vertical: "bottom",
            horizontal: "end",
            slot: "fixed"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, { color: "warning" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: $setup.searchOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_fab_list, { side: "top" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_fab_button, {
                    color: "warning",
                    "router-link": "/events"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: $setup.medalOutline }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_fab_button, {
                    color: "warning",
                    "router-link": "/activity/find"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: $setup.accessibilityOutline }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}